<template>
  <div></div>
</template>
<script>
import { http } from '@/services'

export default {
  name: 'WriteMyWill',
  beforeRouteEnter(to, from, next) {
    if (from.name === 'WriteMyWillName') {
      var url =
        process.env.NODE_ENV === 'production'
          ? 'https://www.mylastwill.co.uk/'
          : 'http://127.0.0.1:8000/'
      window.location.href = url
    } else {
      next()
    }
  },
  created() {
    this.$store.dispatch('logout', true)
  },
  mounted() {
    this.start()
  },
  methods: {
    async start() {
      if (this.$route.path.includes('/coupon/mirror/')) {
        await this.getCouponMirror(this.$route.params.coupon).then(() =>
          this.$router.push({ name: 'WriteMyWillName' }).catch((error) => {
            console.log(error)
          })
        )
      } else if (this.$route.params.coupon) {
        await this.getCoupon(this.$route.params.coupon).then(() =>
          this.$router.push({ name: 'WriteMyWillName' }).catch((error) => {
            console.log(error)
          })
        )
      } else if (localStorage.coupon) {
        this.$store.commit('checkoutBasket', {
          coupon: JSON.parse(localStorage.getItem('coupon'))
        })
        this.$router.push({ name: 'WriteMyWillName' }).catch((error) => {
          console.log(error)
        })
      } else {
        this.$router.push({ name: 'WriteMyWillName' }).catch((error) => {
          console.log(error)
        })
      }
      this.getPrices()
    },
    async getCoupon() {
      return http
        .get('/wills/api/get_code', {
          params: { code: this.$route.params.coupon }
        })
        .then((response) => {
          console.log(response.data)
          if (
            response.data.partner_coupon &&
            response.data.partner_personal_details
          ) {
            this.$store.commit(
              'partnerCheckoutBasket',
              response.data.partner_coupon
            )
            this.$store.commit(
              'partnerPersonalDetails',
              response.data.partner_personal_details
            )
            this.$notification.success('Partner Code?')
          } else {
            this.$store.commit('checkoutBasket', {
              coupon: response.data
            })
            this.$notification.success('Code Applied')
          }
        })
        .catch((error) => {
          this.$notification.error(error.response.data)
        })
    },
    async getCouponMirror() {
      return http
        .get('/wills/api/get_code_mirror', {
          params: { code: this.$route.params.coupon }
        })
        .then((response) => {
          this.$store.commit('checkoutBasket', {
            coupon: response.data.coupon
          })
          this.$store.commit('personalDetails', response.data.personal_details)
          this.$notification.success('Mirror Will Code Applied')
        })
        .catch((error) => {
          console.log(error)
          if (error.response && error.response.data)
            this.$notification.error(error.response.data)
        })
    },
    getPrices() {
      http
        .get('wills/api/prices')
        .then((response) => {
          this.$store.commit('prices', response.data)
          // localStorage.setItem('prices', JSON.stringify(response.data))
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>
